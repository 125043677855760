import { getLocalStorage, writePersistentLocalStorage } from '~/services/LocalStorage/localStorage'

export default defineNuxtRouteMiddleware((to) => {
  let token = getLocalStorage('agentum_token')
  const oldToken = useCookie('agentum_token')
  if (!token && oldToken.value) {
    token = oldToken.value
    writePersistentLocalStorage('agentum_token', token)
    oldToken.value = null
  }
  if (token && to?.name === 'login') {
    return navigateTo('/')
  }

  if (!token && to?.name !== 'login') {
    abortNavigation()
    return navigateTo('/login')
  }
})
